<template>
  <div class="bg-white" style="height: 100%">
    <div class="p-3" style="padding-right: 10%">
      <b-dropdown ref="menu" size="lg" class="mr-2 mb-2" variant="outline-dark" v-for="t in tree" :key="t.name" :text="t.name">
        <li v-for="s in t.sub" :class="{'dropdown-submenu': !s.isLast}" :key="(s.no ? s.no + '. ' : '') + s.name">
          <a class="dropdown-item pointer" :class="s.no ? '' : 'bold'" @click="loadScript(s)">{{(s.no ? s.no + '. ' : '') + s.name}}<i v-if="!s.isLast" class="fa fa-caret-right"></i></a>
          <ul v-if="s.sub && s.sub.filter(e => !e.isLast)" class="dropdown-menu">
            <li v-for="s2 in s.sub" :class="{'dropdown-submenu': !s2.isLast}" :key="(s2.no ? s2.no + '. ' : '') + s2.name">
              <a class="dropdown-item pointer" :class="s2.no ? '' : 'bold'" @click="loadScript(s2)">{{(s2.no ? s2.no + '. ' : '') + s2.name}}<i v-if="!s2.isLast" class="fa fa-caret-right"></i></a>
              <ul v-if="s2.sub && s2.sub.filter(e => !e.isLast)" class="dropdown-menu">
                <li v-for="s3 in s2.sub" :class="{'dropdown-submenu': !s3.isLast}" :key="(s3.no ? s3.no + '. ' : '') + s3.name">
                  <a class="dropdown-item pointer" @click="loadScript(s3)">{{(s3.no ? s3.no + '. ' : '') + s3.name}}<i v-if="!s3.isLast" class="fa fa-caret-right"></i></a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </b-dropdown>
    </div>

    <store-view ref="storeView" v-bind="{mode, favorite}" :obj.sync="obj"></store-view>
  </div>
</template>

<style scoped>
li:hover > ul.dropdown-menu {
  display: block;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}
.dropdown-item {
  border-bottom: 0;
}
i.fa-caret-right {
  position: absolute;
  top: 13px;
  right: 0;
}
</style>

<script>
import StoreView from './StoreView.vue'

export default {
  name: 'DataMenu',
  title: 'Data Menu',
  components: {
    StoreView,
  },
  data() {
    return {
      tree: [],
      no: null,
      mode: 'view',
      items: {list: [], filteredList: [], page: []},
      favorite: {},
      obj: null,
      options: {
        tags: [],
        roles: [],
        menus: [],
        users: [],
      },
    }
  },
  async created() {
    this.loadRoles();
    this.loadUsers();
  },
  async mounted() {
    await this.loadMenuTree();
    const no = this.$route.params.no;
    if (no) {
      this.loadScript({no});
    }
  },
  methods: {
    async loadMenuTree() {
      const j = await this.$api.getJson(`/data/store/loadMenuTree`);
      if (j) {
        this.tree = j.tree;
      }
    },
    async loadRoles() {
      const j = await this.$api.getJson(`/data/store/loadRoles`);
      if (j) {
        this.options.roles = j.roles;
      }
    },
    async loadUsers() {
      const j = await this.$api.getJson('/user/list?proj=id,name,teamId');
      if (j) {
        this.options.users = j.list.map(e => ({label: `${e.name}(${e.id})`, name: e.name, teamId: e.teamId, value: e.id}));
      }
    },

    async loadScript(menu) {
      if (menu.no) {
        this.$refs.menu.forEach(m => m.hide());
        history.replaceState(null, null, location.origin + '/#/data/menu/' + menu.no);
        this.$refs.storeView.loadScript(menu.no);
      }
    },
  }
}
</script>
